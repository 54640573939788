<div class="dialog-view">
  <div class="create-form">
    <div class="mat-h1" style="align-items: center">
      Create Rerun Merging with Config Task
    </div>
    <form [formGroup]="createRerunMergingWithConfigTaskForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="field-full-width">
        <mat-select formControlName="configItemId" placeholder="Choose merging config" required (selectionChange)="changeConfigItem($event)">
          <mat-option *ngFor="let config of configItems" [value]="config.id">
            {{config.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button class="item-spacing mat-raised-button" type="button" (click)="addDebugData()">Add Debug Data</button>
      <div class="item-spacing" formArrayName="debugTaskIds" *ngFor="let debugForm of debugTaskArray.controls; let i = index">
        <div style="display:flex; flex-direction: row" [formGroupName]="i" >
          <mat-form-field class="field-full-width">
            <mat-select formControlName="id" placeholder="Choose debug data">
              <mat-option *ngFor="let debugTask of debugTasks" [value]="debugTask.id">
                {{debugTask.displayName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button type="button" color="warn" (click)="removeDebugData(debugForm)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="createRerunMergingWithConfigTaskForm.pristine || createRerunMergingWithConfigTaskForm.status != 'VALID'">
        SUBMIT
      </button>
    </form>
  </div>
  <div class="config-view">
    <div *ngIf="selectedMergingConfig">
      <ngx-json-viewer [json]="selectedMergingConfig.configData.mergingConfigPreset"></ngx-json-viewer>
    </div>
  </div>
</div>
