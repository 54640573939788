import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { palexy } from '../../../generated/bundle';
import { Task } from '../../../task/task.model';
import { TaskService } from '../../../task/task.service';

@Component({
  selector: 'app-qc-video-info-dialog',
  templateUrl: './qc-video-info-dialog.component.html',
  styleUrls: ['./qc-video-info-dialog.component.css']
})
export class QcVideoInfoDialogComponent implements OnDestroy{
  task: Task;
  store: palexy.streaming.v1.IStore;
  error: string = '';
  qcEmails: string[] = [];
  qcAccounts: palexy.streaming.v1.IAccount[];
  accountFilterCtrl: FormControl = new FormControl();
  filteredAccounts: ReplaySubject<palexy.streaming.v1.IAccount[]> = new ReplaySubject<palexy.streaming.v1.IAccount[]>(1);
  //control
  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  _onDestroy = new Subject<void>();


  storageProviderKeys = Object.keys(palexy.sherlock.v1.SherlockTask.StorageProvider);
  storageProviderEnum = palexy.sherlock.v1.SherlockTask.StorageProvider;
  storageProvider = null;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    public dialogRef: MatDialogRef<QcVideoInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private taskService: TaskService,
    private snackBar: MatSnackBar,
  ) {
    this.task = data.task;
    this.store = data.store;
    this.qcAccounts = data.qcAccounts ?? [];
    this.storageProvider = this.task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storageProvider;
    this.accountFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => this.filterAccounts());
    this.filteredAccounts.next(this.qcAccounts.slice());
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getVideoUrl(qcVideo: palexy.sherlock.v1.IRemoteFile) {
    if (qcVideo.googleCloudStorageFile != null) {
      return qcVideo.googleCloudStorageFile.downloadableUrl;
    }
    if (qcVideo.googleDriveFile != null) {
      return qcVideo.googleDriveFile.downloadableUrl;
    }
    return null;
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let email = (value || '').trim();

    if (this.isValidEmail(email)) {
      if ((value || '').trim()) {
        this.qcEmails.push(value.trim());
        this.autocompleteTrigger.closePanel();
        this.accountFilterCtrl.setValue(null);
      }
      if (input) {
        input.value = '';
      }
    }
  }

  removeEmail(email: string): void {
    const index = this.qcEmails.indexOf(email);

    if (index >= 0) {
      this.qcEmails.splice(index, 1);
      this.filterAccounts();
    }
  }

  isValidEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
  }

  send() {
    this.dialogRef.close(this.qcEmails);
  }

  accountSelected(event: MatAutocompleteSelectedEvent): void {
    this.qcEmails.push(event.option.value);
    this.emailInput.nativeElement.value = '';
    this.accountFilterCtrl.setValue(null);
  }

  filterAccounts() {
    const accounts = this.qcAccounts.filter(account => !this.qcEmails.includes(account.email));
    let searchValue = this.accountFilterCtrl.value as string;
    if (!searchValue) {
      this.filteredAccounts.next(accounts.slice());
    } else {
      searchValue = searchValue.trim().toLowerCase();
      this.filteredAccounts.next(accounts.filter(account =>
          account.email.toLowerCase().includes(searchValue)
          || account.fullName.toLowerCase().includes(searchValue)
          || account.username.toLowerCase().includes(searchValue)
      ))
    }
  }

  openPanel() {
    this.autocompleteTrigger.openPanel();
  }
}


