<h2 mat-dialog-title>Qc Video Info</h2>
<mat-dialog-content>
    <div>
      <b>Store:</b> {{store.displayName}}
    </div>
    <div>
      <b>DateId:</b>
      {{task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.dateId}} 
      ({{task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.startHour}} - {{task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.endHour}})
    </div>
    <div>
      <b>Anonymize face:</b> {{ task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.anonymizeFace ? 'True' : 'False' }}
    </div>
    <div *ngIf="task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.anonymizeFace">
      <b>FPS:</b> {{ task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.fps }}
    </div>
    <div>
      <b>Cameras:</b>
      <ul>
        <li *ngFor="let cameraId of task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.cameraIds">{{cameraId}}</li>
      </ul>
    </div>
    <div>
      <b>Storage Provider:</b> {{storageProvider}}
    </div>
    <div>
      <b>Emails:</b>
      <ul>
        <li *ngFor="let email of task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.notificationEmails">{{email}}</li>
      </ul>
    </div>
    <div>
      <b>Status:</b> {{task.taskStatus}}
    </div>
    <div *ngIf="task.taskStatus == 'STATUS_DONE'">
      <b>Videos:</b>
      <div *ngIf="storageProvider=='GOOGLE_DRIVE'">
        <a href="{{task.taskResults[0].coreTaskResult.prepareQcVideosResult.googleDriveTaskResultWebViewLink}}">Google Drive</a>
      </div>
      <div *ngIf="storageProvider=='GOOGLE_DRIVE'">
        <b>Google Drive Accout: </b> {{task.taskResults[0].coreTaskResult.prepareQcVideosResult.qcVideos[0].googleDriveFile.owner}}
      </div>
      <div *ngIf="storageProvider=='GCP'">
        <ul>
          <li *ngFor="let video of task.taskResults[0].coreTaskResult.prepareQcVideosResult.qcVideos">
            <a href="{{getVideoUrl(video)}}">{{video.filename}}</a>
          </li>
        </ul>
      </div>
    </div>

    <form *ngIf="task.taskStatus == 'STATUS_DONE'">
      <mat-form-field class="full-width">
        <mat-label>Notification emails</mat-label>
        <mat-chip-list #chipList aria-label="Notification emails">
          <mat-chip *ngFor="let email of qcEmails" [selectable]="false"
                   [removable]="true" (removed)="removeEmail(email)">
            {{email}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Add email..."
                 #emailInput
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="true"
                 (matChipInputTokenEnd)="addEmail($event)"
                 [formControl]="accountFilterCtrl"
                 [matAutocomplete]="auto"
                 (click)="openPanel()"
          >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="accountSelected($event)">
          <mat-option *ngFor="let account of filteredAccounts | async" [value]="account.email">
            <div class="account-info">
              <div class="account-info-email">{{account.email}}</div>
              <p>Username: {{account.username}}, Name: {{account.fullName}}</p>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <div *ngIf="error">Error: {{error}}</div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button mat-primary (click)="send()" *ngIf="task.taskStatus == 'STATUS_DONE'">Send Notification</button>
</mat-dialog-actions>