import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, forkJoin, of } from 'rxjs';
import { map, startWith } from "rxjs/operators";
import { DateService } from '../../common/date';
import { palexy } from '../../generated/bundle';
import { Task } from '../../task/task.model';
import { TaskService } from '../../task/task.service';
import { CameraService } from '../camera.service';
import { EmailService } from '../email.service';
import { QcVideoInfoDialogComponent } from './qc-video-info-dialog/qc-video-info-dialog.component';
import { StoreService } from '../../common/store.service';
import { AccountService } from '../../common/account.service';


@Component({
  selector: 'app-qc-video-list',
  templateUrl: './qc-video-list.component.html',
  styleUrls: ['./qc-video-list.component.css']
})
export class QcVideoListComponent implements OnInit {

  prepareQcVideoForm: FormGroup;
  stores: palexy.streaming.v1.IStore[] = [];
  qcAccounts: palexy.streaming.v1.IAccount[];
  filteredStores: Observable<palexy.streaming.v1.IStore[]>;

  storageProviderKeys = Object.keys(palexy.sherlock.v1.SherlockTask.StorageProvider);
  storageProviderEnum = palexy.sherlock.v1.SherlockTask.StorageProvider;

  storeNameAutoCompleteControl = new FormControl();
  storeIdMap: Map<number, palexy.streaming.v1.IStore> = new Map();

  errorMessage = "";
  isLoading = false;

  prepareQcVideosTasks: Task[] = [];
  storeCameras: palexy.streaming.v1.ICamera[] = [];

  isFilterCameras: boolean = false;
  qcEmails: string[] = [];

  totalItem: number = 0;
  pageSize: number = 100;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    storeService: StoreService,
    private snackBar: MatSnackBar,
    private dateService: DateService,
    private taskService: TaskService,
    private emailService: EmailService,
    private cameraService: CameraService,
    private accountService: AccountService
  ) {
    this.prepareQcVideoForm = this.fb.group({
      selectedStoreDisplayName: ['', Validators.required],
      selectedDate: ['', Validators.required],
      startHour: [8, Validators.required],
      endHour: [21, Validators.required],
      cameraIds: [],
      isFilterCameras: [false],
      storageProvider: ["GOOGLE_DRIVE", Validators.required],
      anonymizeFace: [false, Validators.required],
      sendMail: [false],
      fps: [1],
      createQcPlan: [true]
    });
    this.prepareQcVideoForm.get('cameraIds').disable();

    this.prepareQcVideoForm.get('isFilterCameras').valueChanges.subscribe(value => {
      let cameraIdsControl = this.prepareQcVideoForm.get('cameraIds');
      if (value) {
        cameraIdsControl.enable();
        cameraIdsControl.setValue(this.storeCameras);
      } else {
        cameraIdsControl.disable();
        cameraIdsControl.setValue([]);
      }
    })

    this.isLoading = true;

    let fetchTasks = taskService.fetchTasks(null, null, palexy.sherlock.v1.SherlockTask.TaskType.TYPE_PREPARE_QC_VIDEOS, true);
    let fetchStores = storeService.fetchStores();
    let fetchQcAccounts = accountService.listQcAccounts();

    forkJoin([fetchTasks, fetchStores, fetchQcAccounts]).subscribe(
      response => {
        // Tasks
        this.prepareQcVideosTasks = response[0].sherlockTasks;

        // Stores
        this.stores = response[1].stores;
        this.filteredStores = this.prepareQcVideoForm.get('selectedStoreDisplayName').valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this._filter(name) : this.stores.slice())
          );
        for (let store of this.stores) {
          this.storeIdMap.set(store.id, store);
        }
        // QC Accounts
        this.qcAccounts = response[2].accounts;
        this.isLoading = false;
      },
      error => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    )
  }

  ngOnInit() {
  }

  private _filter(value: string): palexy.streaming.v1.IStore[] {
    const filterValue = value.toLowerCase();
    return this.stores.filter(store => store.displayName.toLowerCase().indexOf(filterValue) >= 0);
  }

  fileBytes: Uint8Array;
  fileToUpload: File = null;

  private _findStoreByDisplayName(name: string): palexy.streaming.v1.IStore{
    return this.stores.filter(store => store.displayName == name)[0];
  }

  updateStoreCameras(store: palexy.streaming.v1.IStore) {
    this.cameraService.listCamera(store.id, true, true).subscribe((cameras) => {
      this.storeCameras = cameras;
      this.prepareQcVideoForm.get('cameraIds').setValue([]);
    });
  }


  submit() {
    this.errorMessage = "";

    const formModel = this.prepareQcVideoForm.value;

    let startHour = formModel.startHour as number;
    let endHour = formModel.endHour as number;

    if (startHour < 0 || startHour > 23) {
      this.errorMessage = 'Start Hour is invalid';
      return;
    }

    if ( endHour < 0 || endHour > 23) {
      this.errorMessage = 'End Hour is invalid';
      return;
    }

    if (endHour < startHour) {
      this.errorMessage = 'End Hour cannot be smaller than Start Hour';
      return;
    }

    if (formModel.anonymizeFace && formModel.fps < 1) {
      this.errorMessage = "Invalid fps, fps should be greater than 0";
      return;
    }

    this.isLoading = true;

    let request: palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest = palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest.create();
    request.endHour = endHour;
    request.startHour = startHour;
    request.dateId = this.dateService.convertToPalexyStandardString(formModel.selectedDate).substring(0, 8);
    request.storeId = this._findStoreByDisplayName(formModel.selectedStoreDisplayName).id;
    request.storageProvider = this.storageProviderEnum[formModel.storageProvider as string];
    request.compressVideo = false;
    request.notificationEmails = this.qcEmails;
    request.anonymizeFace = formModel.anonymizeFace;
    request.fps = formModel.fps;
    request.createQcPlan = formModel.createQcPlan;

    if (formModel.isFilterCameras && formModel.cameraIds.length > 0) {
      request.cameraIds = formModel.cameraIds;
    }

    this.taskService.createPrepareQcVideosTask(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = JSON.parse(err._body).error;
      this.isLoading = false;
    });
  }

  openQcVideoInfoDialog(task: Task) {
    let store: palexy.streaming.v1.IStore = this.storeIdMap.get(+task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storeId);
    let dialogRef = this.dialog.open(QcVideoInfoDialogComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        task: task,
        store: store,
        qcAccounts: this.qcAccounts
      }
    });
    dialogRef.afterClosed().subscribe(emails => {
      if (emails != null && emails.length > 0) {
        let video_list_html = ""

        if (task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storageProvider == this.storageProviderEnum[this.storageProviderEnum.GCP]) {
          for (let video of task.taskResults[0].coreTaskResult.prepareQcVideosResult.qcVideos) {
            video_list_html += `<li><a href="${video.googleCloudStorageFile.downloadableUrl}">${video.filename}</a></li>`
          }
          video_list_html = '<ol>' + video_list_html + '</ol>'
        } else if (task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storageProvider == this.storageProviderEnum[this.storageProviderEnum.GOOGLE_DRIVE]) {
          let driveUrl = task.taskResults[0].coreTaskResult.prepareQcVideosResult.googleDriveTaskResultWebViewLink;
          video_list_html = `<a href="${driveUrl}">Google Drive</a>`;
        }

        let request: palexy.streaming.v1.SendSimpleMessageRequest = palexy.streaming.v1.SendSimpleMessageRequest.create();
        request.to = emails
        request.subject = `QC videos for store ${store.displayName} on ${task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.dateId}`
        request.message = `
        <p>Store: ${store.displayName}</p>
        <p>Date: ${task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.dateId}</p>
        <p>Videos:</p> ${video_list_html}
        <p>All links will expire after 72h. Please download all videos as soon as possible!</p>
        <p>This is an automated email, please do not reply. For any issue, please contact our support at <b>support@palexy.com</b>.</p>
        <p><b>Palexy Team</b></p>
        `
        this.emailService.sendSimpleMessage(request).subscribe(response => {
          let responseMessage = response.statusCode == 0 ? "Success" : "Fail"
          this.snackBar.open(responseMessage, null, { duration: 3000});
        });
      }
    })
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let email = (value || '').trim();

    if (this.isValidEmail(email)) {
      if ((value || '').trim()) {
        this.qcEmails.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
    }
  }

  removeEmail(email: string): void {
    const index = this.qcEmails.indexOf(email);

    if (index >= 0) {
      this.qcEmails.splice(index, 1);
    }
  }

  isValidEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
  }

  getHourSlotDetail(task: Task) {
    let getHour = (hour) => {
      return hour ? hour : 0;
    }

    let startHour = getHour(task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.startHour);
    let endHour = getHour(task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.endHour);
    return `(${startHour} -> ${endHour})`;
  }
}


