import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { palexy } from '../../../generated/bundle';
import { UtilsService } from '../../../common/utils';
import { QcService } from '../../qc.service';
import { DateService } from '../../../common/date';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from '../../../common/company.service';
import { StoreService } from '../../../common/store.service';


@Component({
  selector: 'create-qc-plan-dialog',
  templateUrl: './create-qc-plan-dialog.component.html',
  styleUrls: ['./create-qc-plan-dialog.component.css']
})
export class CreateQcPlanDialogComponent {

  companyControl = new FormControl();
  companyFilterControl = new FormControl();
  storeControl = new FormControl();
  storeFilterControl = new FormControl();
  startDateControl = new FormControl();
  endDateControl = new FormControl();
  isSelectingAllStores: boolean = false;

  filteredStores: BehaviorSubject<palexy.streaming.v1.IStore[]> = new BehaviorSubject<palexy.streaming.v1.IStore[]>([]);
  filteredCompanies: BehaviorSubject<palexy.streaming.v1.ICompany[]> = new BehaviorSubject<palexy.streaming.v1.ICompany[]>([]);

  companyStores: palexy.streaming.v1.IStore[] = [];

  stores: palexy.streaming.v1.IStore[];
  companies: palexy.streaming.v1.ICompany[];
  storeNameMap: Map<string, palexy.streaming.v1.IStore> = new Map();

  errorMessage: string = null;
  isSaving: boolean = false;

  private _onDestroy = new Subject<void>();

  @ViewChild('storeInput') storeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    storeService: StoreService,
    private qcService: QcService,
    companyService: CompanyService,
    private dateService: DateService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<CreateQcPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    const fetchCompanies = companyService.getCompanies();
    const fetchStores = storeService.fetchStores();
    forkJoin([fetchCompanies, fetchStores]).subscribe(results => {
      this.companies = results[0].companies;
      this.stores = results[1].stores;

      for (const store of this.stores) {
        this.storeNameMap.set(store.displayName, store);
      }

      this.filteredCompanies.next(this.companies);
      this.companyFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.utilsService.filter(this.companies, this.filteredCompanies, this.companyFilterControl, c => c.name)
        });
      this.companyStores = this.stores;
      this.companyControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((company: palexy.streaming.v1.ICompany) => {
          this.selectCompany(company);
        })

      this.filteredStores.next(this.stores);
      this.storeFilterControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => this.utilsService.filter(this.companyStores, this.filteredStores, this.storeFilterControl, s => s.displayName));

      this.storeControl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((selectedStores: palexy.streaming.v1.IStore[]) => {
          this.isSelectingAllStores = selectedStores?.length === this.companyStores.length;
        })
    })
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectCompany(company: palexy.streaming.v1.ICompany) {
    this.companyStores = this.stores.filter(s => s.companyId == company.id);
    this.storeControl.setValue([]);
    this.filteredStores.next(this.companyStores);
  }

  onToggleSelectAllStores(isToggleAll: boolean) {
    if (isToggleAll) {
      this.storeControl.setValue(this.companyStores);
    } else {
      this.storeControl.setValue([]);
    }
  }

  craftQcPlanItem(store: palexy.streaming.v1.IStore, dateId: string): palexy.sherlock.v1.QcPlanItem {
    const item = palexy.sherlock.v1.QcPlanItem.create();
    item.dateId = dateId;
    item.storeId = store.id;
    item.companyId = store.companyId;
    item.isCustomItem = true;
    return item;
  }

  submit() {
    const planItems: palexy.sherlock.v1.QcPlanItem[] = [];
    const dateIds = this.dateService.generateDateIdList(this.startDateControl.value, this.endDateControl.value);
    const qcPlanItems = [];
    const selectedStores: palexy.streaming.v1.IStore[] = this.storeControl.value;
    for (const store of selectedStores) {
      for (const dateId of dateIds) {
        qcPlanItems.push(this.craftQcPlanItem(store, dateId));
      }
    }

    const request = palexy.sherlock.v1.BatchCreateQcPlanItemsRequest.create();
    request.items = qcPlanItems;

    this.errorMessage = null;
    this.isSaving = true;
    this.qcService.batchCreateQcPlanItems(request).subscribe(
      response => {
        this.isSaving = false;
        this.dialogRef.close(response.items)
      },
      error => {
        this.isSaving = false;
        this.errorMessage = error?.error?.message ?? error.message;
      }
    )
  }

  cancel() {
    this.dialogRef.close();
  }
}


