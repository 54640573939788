import { Component, Inject } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { palexy } from "../../generated/bundle";
import { StoreConfigItem } from "../storeconfig.model";
import { Task } from "../task.model";
import { CreateDebugDataDialogComponent } from "./create-debug-data-dialog.component";

@Component({
  selector: 'app-create-rerun-merging-with-config-task-dialog',
  templateUrl: './create-rerun-merging-with-config-task-dialog.component.html',
  styleUrls: ['./create-rerun-merging-with-config-task-dialog.component.css']
})
export class CreateRerunMergingWithConfigTaskDialogComponent {
  createRerunMergingWithConfigTaskForm: FormGroup;
  storeId: number;
  storeConfigId: number;
  configItems: StoreConfigItem[];
  debugTasks: Task[];
  selectedMergingConfig: StoreConfigItem;

  constructor(
    public dialogRef: MatDialogRef<CreateDebugDataDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.storeId = data.storeId;
    this.storeConfigId = data.storeConfigId;
    this.configItems = data.configItems;
    this.debugTasks = data.debugTasks;
    this.createForm();
  }

  createForm() {
    this.createRerunMergingWithConfigTaskForm = this.fb.group({
      configItemId: ['', Validators.required],
      debugTaskIds: this.fb.array([], Validators.required)
    });
  }

  get debugTaskArray() {
    return this.createRerunMergingWithConfigTaskForm.get('debugTaskIds') as FormArray;
  }

  addDebugData() {
    this.debugTaskArray.push(
      this.fb.group({
        id: ['', Validators.required]
      })
    )
  }

  removeDebugData(form) {
    this.debugTaskArray.controls = this.debugTaskArray.controls.filter(control => control != form);
    this.debugTaskArray.setValue(this.debugTaskArray.controls.map(control => control.value));
  }

  changeConfigItem(event) {
    this.selectedMergingConfig = this.configItems.find(item => item.id == event.value);
  }

  prepareRerunMergingWithConfigRequest(){
    let request = palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest.create();
    request.configItem = palexy.streaming.v1.StoreConfigItem.fromObject(this.selectedMergingConfig);
    request.debugTaskIds = [];
    this.createRerunMergingWithConfigTaskForm.value.debugTaskIds.forEach(item => {
      request.debugTaskIds.push(+item['id']);
    })
    return request;
  }

  onSubmit() {
    let request = this.prepareRerunMergingWithConfigRequest();
    this.dialogRef.close(request);
  }
}
