import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {palexy} from "../generated/bundle";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private http: HttpClient) {}

  listQcAccounts(): Observable<palexy.streaming.v1.ListAccountsResponse> {
    return this.http.post('/api/v1/account:listPalexyQcAccounts', {})
      .pipe(map(resp => palexy.streaming.v1.ListAccountsResponse.fromObject(resp)));
  }
}