import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { palexy } from "../generated/bundle";
import { StoreConfigItem } from "./storeconfig.model";
import {
  CreateTaskRequest,
  ListTasksResponse,
  ListTasksResponseFromServer,
  Task,
  TaskFromServer,
} from "./task.model";

@Injectable()
export class TaskService {
  constructor(private http: HttpClient) {}

  fetchTasks = (task_status=null, store_config_id=null, task_type=null, include_task_results=null, page_index=0, page_size=200): Observable<ListTasksResponse> => {
    let param = [
      `pageIndex=${page_index}`,
      `pageSize=${page_size}`
    ];
    if (task_status != null && task_status != "") param.push(`taskFilter.taskStatus=${task_status}`);
    if (store_config_id != null) param.push(`taskFilter.storeConfigId=${store_config_id}`);
    if (task_type != null) param.push(`taskFilter.taskType=${task_type}`);
    if (include_task_results != null) param.push(`includeTaskResults=${include_task_results}`);

    let url = 'api/v1/tasks?' + param.join('&');
    return this.http
      .get(url)
      .pipe(map(resp => ListTasksResponseFromServer(resp)));
  }

  createTask = (newTask: Task): Observable<Task> =>
    this.http
        .post(`api/v1/tasks`, new CreateTaskRequest(newTask))
        .pipe(map(resp => TaskFromServer(resp)));

  getTaskByName = (name: string): Observable<Task> =>
    this.http
      .get(`api/v1/${name}`)
      .pipe(map(resp => TaskFromServer(resp)));

  getTaskResultOfTask = (taskName: string): Observable<palexy.sherlock.v1.ListTaskResultsResponse> => {
      return this.http
        .get(`api/v1/${taskName}/results`)
        .pipe(map(resp => palexy.sherlock.v1.ListTaskResultsResponse.fromObject(resp)));
  }

  createDebugTaskForStoreConfig = (request: palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest): Observable<any> => {
    return this.http
        .post(`api/v1/tasks:createDebugTaskForStoreConfig`, request.toJSON());
  }

  createDebugTaskForExperiment = (request: palexy.sherlock.v1.CreateDebugTaskForExperimentRequest): Observable<any> => {
    return this.http
      .post(`api/v1/tasks:createDebugTaskForExperiment`, request.toJSON());
  }

  updateHumanLabelLink = (request: palexy.sherlock.v1.UpdateHumanLabelLinkRequest): Observable<any> => {
    return this.http
        .post(`api/v1/${request.taskResult}:updateHumanLabelLink`, request.toJSON());
  }

  retryTask = (taskName: string): Observable<any> =>
    this.http
        .post(`api/v1/${taskName}:retry`, {task: taskName});

  deleteTask = (taskName: string): Observable<any> =>
    this.http
        .delete(`api/v1/${taskName}`);

  createAccuracyTaskForStoreConfig = (request: palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest): Observable<any> => {
    return this.http
        .post(`api/v1/tasks:createAccuracyTaskForStoreConfig`, request.toJSON());
  }

  createPostMergeTasksForStoreConfig = (configItem: StoreConfigItem): Observable<any> => {
      return this.http
          .post(`api/v1/tasks:createTasksForConfigItem`, {
            config_item: configItem
          });
    }

  createPrepareQcVideosTask = (request: palexy.sherlock.v1.CreatePrepareQcVideosTaskRequest): Observable<Task> => {
    return this.http
      .post<any>(`api/v1/tasks:createPrepareQcVideosTask`, request)
      .pipe(map(resp => TaskFromServer(resp.tasks[0])));
  }

  generateSignedUrl = (unsignedUrl: String, expiration: number = 600): Observable<any> => {
    unsignedUrl = unsignedUrl.split('?')[0];
    let parts = unsignedUrl.split('/');
    let request = palexy.sherlock.v1.GenerateSignedUrlRequest.create();
    request.bucketName = 'buckets/' + parts[parts.length - 2];
    request.objectName = 'objects/' + parts[parts.length - 1];
    request.expiration = expiration;
    return this.http
      .post(`api/v1/cloudstorage/${request.bucketName}/${request.objectName}:generateSignedUrl`, request);
  }

  createRerunMergingWithConfigTaskForConfigItem = (request: palexy.sherlock.v1.CreateRerunningTaskForConfigItemRequest): Observable<Task> => {
    return this.http
    .post<any>(`api/v1/tasks:createRerunningTaskForConfigItem`, request)
    .pipe(map(resp => TaskFromServer(resp.tasks[0])));
  }
}
