import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplyconfigBtnComponent } from './appy-config-btn/apply-config-btn.component';
import { UpdateToProductionDialogComponent } from './appy-config-btn/update-to-production-dialog.component';
import { ConfigListComponent } from './store-config-detail/config-list.component';
import { CreateDebugDataDialogComponent } from './store-config-detail/create-debug-data-dialog.component';
import { CreateDebugExperimentDialogComponent } from "./store-config-detail/create-debug-experiment-dialog.component";
import { StoreConfigDetailComponent } from './store-config-detail/store-config-detail.component';
import { StoreConfigEditComponent } from './store-config-edit/store-config-edit.component';
import { StoreConfigItemEditComponent } from './store-config-item-edit/store-config-item-edit.component';
import { StoreConfigListComponent } from './store-config-list/store-config-list.component';
import { StoreConfigService } from './storeconfig.service';
import { DeleteTaskBtnComponent } from './task-delete-btn/task-delete-btn.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { TaskListComponent } from './task-list/task-list.component';
import { ListAccuracyResultComponent } from './task-list/views/list-accuracy-result/list-accuracy-result.component';
import { ListDebugExperimentComponent } from './task-list/views/list-debug-experiment/list-debug-experiment.component';
import { ListDebugMergeComponent } from './task-list/views/list-debug-merge/list-debug-merge.component';
import { DebugPersonResultComponent } from './task-result/debug-person-result/debug-person-result.component';
import { UpdateHumanLabelLinkDialogComponent } from './task-result/debug-person-result/update-human-label-link-dialog.component';
import { PostMergeResultComponent } from './task-result/post-merge-result/post-merge-result.component';
import { RetryTaskBtnComponent } from './task-retry-btn/task-retry-btn.component';
import { TaskRoutingModule } from './task.routing.module';
import { TaskService } from './task.service';

import { RerunMergingDialogComponent } from './rerun-merging-dialog/rerun-merging-dialog.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkDetailRowDirective } from './store-config-detail/cdk-detail-row.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MergingConfigPresetListComponent } from './merging-config-preset-list/merging-config-preset-list.component';
import { MergingConfigPresetItemComponent } from './merging-config-preset-item/merging-config-preset-item.component';
import { MapVToKPipe } from '../common/misc';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MergingConfigFormComponent } from './merging-config-form/merging-config-form.component';
import { DatePipe } from '@angular/common';
import {
  CreateRerunMergingWithConfigTaskDialogComponent
} from "./store-config-detail/create-rerun-merging-with-config-task-dialog.component";

@NgModule({
  declarations: [
    TaskListComponent,
    TaskEditComponent,
    TaskDetailComponent,
    DebugPersonResultComponent,
    StoreConfigListComponent,
    StoreConfigEditComponent,
    StoreConfigDetailComponent,
    StoreConfigItemEditComponent,
    CreateDebugDataDialogComponent,
    CreateDebugExperimentDialogComponent,
    UpdateHumanLabelLinkDialogComponent,
    RetryTaskBtnComponent,
    DeleteTaskBtnComponent,
    ListDebugMergeComponent,
    ListDebugExperimentComponent,
    ListAccuracyResultComponent,
    ConfigListComponent,
    ApplyconfigBtnComponent,
    UpdateToProductionDialogComponent,
    PostMergeResultComponent,
    CdkDetailRowDirective,
    RerunMergingDialogComponent,
    MergingConfigPresetListComponent,
    MergingConfigPresetItemComponent,
    MapVToKPipe,
    MergingConfigFormComponent,
    CreateRerunMergingWithConfigTaskDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatChipsModule,
    MatTableModule,
    CdkTableModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTableModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    TaskRoutingModule,
    NgxJsonViewerModule,
    MatTooltipModule,
  ],
  providers: [
    TaskService,
    StoreConfigService,
    MapVToKPipe,
    DatePipe,
  ],
  entryComponents: [
    CreateDebugDataDialogComponent,
    CreateDebugExperimentDialogComponent,
    UpdateHumanLabelLinkDialogComponent,
    UpdateToProductionDialogComponent,
    RerunMergingDialogComponent,
  ],
  bootstrap: [],
  exports: [
    TaskListComponent,
    TaskEditComponent,
    TaskDetailComponent,
    DebugPersonResultComponent,
    StoreConfigListComponent,
    StoreConfigEditComponent,
    StoreConfigDetailComponent,
    StoreConfigItemEditComponent,
    CreateDebugDataDialogComponent,
    CreateDebugExperimentDialogComponent,
    UpdateHumanLabelLinkDialogComponent,
    RetryTaskBtnComponent,
    DeleteTaskBtnComponent,
    ListDebugMergeComponent,
    ListDebugExperimentComponent,
    ListAccuracyResultComponent,
    ConfigListComponent,
    ApplyconfigBtnComponent,
    UpdateToProductionDialogComponent,
    PostMergeResultComponent,
    CreateRerunMergingWithConfigTaskDialogComponent
  ]
})
export class TaskModule { }

