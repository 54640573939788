<div class="dialog-container">
  <div class="dialog-header">
    <h2 mat-dialog-title>Create QC Plan Items</h2>
  </div>

  <div class="form">
    <mat-form-field class="field-full-width">
      <mat-select
        [formControl]="companyControl"
        placeholder="Select company to filter stores of that company">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="companyFilterControl"
            placeholderLabel="Company"
            noEntriesFoundLabel="No results found">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field-full-width">
      <mat-label>Store</mat-label>
      <mat-select
        [formControl]="storeControl"
        [multiple]="true"
        [required]="true">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="storeFilterControl"
            placeholderLabel="Type to search"
            noEntriesFoundLabel="No results found"
            [showToggleAllCheckbox]="!storeFilterControl.value"
            (toggleAll)="onToggleSelectAllStores($event)"
            [toggleAllCheckboxChecked]="isSelectingAllStores"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let store of filteredStores | async" [value]="store">
          {{ store.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field-full-width">
      <mat-label>Date range</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker" required>
        <input matStartDate placeholder="Start date" [formControl]="startDateControl" readonly required>
        <input matEndDate placeholder="End date" [formControl]="endDateControl" readonly required>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>

    <div class="error" *ngIf="errorMessage">
      Error: {{ errorMessage }}
    </div>
  </div>

  <div class="dialog-footer">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button mat-raised-button mat-primary color="primary"
      (click)="submit()"
      [disabled]="!(startDateControl.valid && endDateControl.valid && storeControl.valid)">
      Create
    </button>
  </div>
</div>
